import { useRouter } from 'next/router';
import NextI18Next, { useTranslation } from '@Lib/i18n';
import { getFooterOrder } from '@Umbraco/footer';
import { geti18nLanguage } from '@Helpers/language/geti18nLanguage';
import { useFooterData } from '@Helpers/footer';
import { useDidMountEffect } from '@Helpers/hooks/useDidMountEffect';
import { useDispatch } from 'react-redux';
import { SET_FOOTER_ORDER } from '@Reducers/sections/actions';
import FooterLinkGroup from './FooterLinkGroup';

const FooterLinks = ({ qrCode, footerOrder }) => {
	const language = geti18nLanguage(NextI18Next.i18n.language);
	const { t } = useTranslation(['common', 'footer']);
	const router = useRouter();
	const { market } = router.query;
	const dispatch = useDispatch();

	const footerLinks = useFooterData(footerOrder, qrCode, t, language);

	useDidMountEffect(() => {
		getFooterOrder(market as string, language).then(footerOrder => dispatch(SET_FOOTER_ORDER(footerOrder)));
	}, [language]);

	return (
		<div className="ncj-footer__links grid--item-gap-5">
			{footerLinks.map((group, index) => (
				<FooterLinkGroup key={index + language} group={group} />
			))}
		</div>
	);
};

export default FooterLinks;
