import routes, { getRoutesAs } from '@Routes';
import { getCustomRoute } from '@Helpers/redirection/getCustomRoute';
import { FooterGroups } from '@Constants/footer';
import { LEGAL_ID_TO_TAB } from '@Constants/legal';

export const FOOTER_BASE_URL = {
	[FooterGroups.GMBH]: routes.LEGAL_TAB,
	[FooterGroups.DATA_PROTECTION]: routes.LEGAL_TAB,
	[FooterGroups.LEGAL_NOTICE]: routes.LEGAL_TAB,
	[FooterGroups.TERMS_OF_USAGE]: routes.LEGAL_TAB,
	[FooterGroups.COOKIES]: routes.COOKIE_SETTINGS,
};

export const getFooterRouteTemplate = (footerGroup, customLegalRoute = null) => {
	const result = FOOTER_BASE_URL[footerGroup];
	return getCustomRoute(customLegalRoute, result || routes.LEGAL_TAB);
};

export const getFooterRoute = (market: string, footerGroup, customLegalRoute = null) => {
	return getRoutesAs(getCustomRoute(customLegalRoute, FOOTER_BASE_URL[footerGroup]), {
		market,
		tab: LEGAL_ID_TO_TAB[footerGroup],
	});
};
