import { useRouter } from 'next/router';
import { GoToUrl } from '@Helpers/ExternalURLs';
import { PushDataToTagManagerForButtons } from '@Helpers/google-analytics/pushDataToTagManager';
import { Buttons, Components, Pages, getComponentId } from '@Constants/google-analytics';
import { WindowTargetAttributes } from '@Constants/common';
import { Markets } from '@Constants/market';
import './index.scss';

const FooterQR = ({ qrCode }) => {
	const router = useRouter();
	const { market } = router.query;
	const isTRMarket = market === Markets.TURKEY;

	const onQRCodeClick = e => {
		PushDataToTagManagerForButtons(e.target.id, window.location.href);
		GoToUrl(qrCode?.website, WindowTargetAttributes.BLANK);
	};

	return (
		<>
			{isTRMarket && (
				<div className="ncj-footer__qr disp-flex">
					<img
						className="cursor-pointer"
						src={qrCode?.qrCode}
						alt={qrCode?.altText}
						onClick={onQRCodeClick}
						id={getComponentId([Pages.GLOBAL, Components.FOOTER, Buttons.QR_CODE])}
					/>
				</div>
			)}
		</>
	);
};

export default FooterQR;
