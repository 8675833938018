import getAppEnvironment from '@Helpers/common/getAppEnvironment';
import getConfig from 'next/config';
import { Environments } from '@Constants/common';

const AppVersion = () => {
	const { publicRuntimeConfig } = getConfig();
	const APP_VERSION = publicRuntimeConfig.VERSION || '';
	const APP_ENV = getAppEnvironment();

	return (
		APP_VERSION &&
		APP_ENV !== Environments.PROD && (
			<div className="disp-flex grid--item-gap-2 color-silver fs-16 m-t-1">
				{APP_ENV !== Environments.LOCAL && <div>{APP_ENV}</div>}
				<div>{APP_VERSION}</div>
			</div>
		)
	);
};

export default AppVersion;
